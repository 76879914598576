// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bH_kC";
export var caseStudyBackground__lineColor = "bH_kz";
export var caseStudyHead__imageWrapper = "bH_kv";
export var caseStudyProjectsSection = "bH_kD";
export var caseStudyQuote__bgRing = "bH_kx";
export var caseStudySolution__ring = "bH_kH";
export var caseStudySolution__ringThree = "bH_lr";
export var caseStudySolution__ringTwo = "bH_kJ";
export var caseStudyVideo__ring = "bH_kN";
export var caseStudy__bgDark = "bH_ks";
export var caseStudy__bgLight = "bH_kr";
export var caseStudy__bgLightGray = "bH_lz";